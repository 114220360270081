import { $themeConfig } from '@themeConfig'

export default {

  // api base url
  baseUrl: $themeConfig.app.apiBaseUrl,

  // api endpoints
  endpoints: {
    googleAuth: '/api/google/auth',

    // Converifai
    billing: '/api/billing',
    clients: '/api/ecomm/agency/clients',
    conversions: '/api/conversions',
    dashboard: '/api/dashboard',
    domain: '/api/users/me/agency/clients/domain',
    forms: '/api/forms',
    googleads: '/api/ggads',
    twilioNumbers: '/api/twilio/phonenumbers',
    reports: '/api/reports',
    users: '/api/users',

    login: '/api/session/login',
    register: '/api/session/register',
    email: '/api/session/email',
    session: '/api/session',
    refresh: '/api/refresh-token', // TODO
    logout: '/api/logout', // TODO
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
