export const initialAbility = [
  {
    action: 'read',
    subject: ['Auth', 'Misc'],
  },
]

export const adminAbility = [
  // role 'admin' (1)
  {
    action: 'manage',
    // all resource types.
    // NOTE: subject is same as resource in the route files
    subject: ['all', 'role-admin'],
  },
]

export const userAbility = [
  // role 'normal user' (2)
  {
    action: 'manage',
    subject: ['role-user', 'Auth', 'Misc', 'Dashboard', 'Campaign', 'Company'],
  },
]

export const _ = undefined
