import useJwt from '@/auth/jwt/useJwt'
import {
  adminAbility,
  // userAbility,
} from '@/libs/acl/config'

export default {
  namespaced: true,
  state: {
    currentUser: null, // current user data
  },
  getters: {
    getCurrentUser(state) {
      return state.currentUser
    },
  },
  mutations: {
    SET_CURRENT_USER(state, userData) {
      state.currentUser = userData
    },
  },
  actions: {
    fetchCurrentUser({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt
          .getCurrentUser()
          .then(userResponse => {
            const userData = userResponse.data.data

            // set user's access ability -- TODO: create different roles
            if (userData.role.id === 1 || userData.role.name === 'admin') {
              // admin role
              userData.ability = adminAbility
            } else {
              // default user role
              userData.ability = adminAbility // userAbility
            }

            // set user's full name for display in app
            userData.fullName = `${userData.name}`
            console.log('getCurrentUser', userData)

            localStorage.setItem('userData', JSON.stringify(userData)) // TODO: cookies instead of localstorage

            commit('SET_CURRENT_USER', userData)
            resolve(userData)
          })
          .catch(error => {
            console.log('getCurrentUser ERROR', error)
            commit('SET_CURRENT_USER', error.response.data)
            reject(error.response.data)
          })
      })
    },
  },
}
