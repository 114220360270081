export default [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/dashboard/Onboarding.vue'),
    meta: {
      resource: 'Onboarding',
      action: 'view',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'view',
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/dashboard/Onboarding.vue'),
    meta: {
      resource: 'Onboarding',
      action: 'view',
    },
  },
]
