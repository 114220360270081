import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    conversions: null, // list of conversions
    verifiedConversions: null, // list of verified conversions
    conversionReportSpam: null, // url of csv
  },
  getters: {
    getAllConversions(state) {
      return state.conversions
    },
    getVerifiedConversions(state) {
      return state.verifiedConversions
    },
    getConversionReportSpam(state) {
      return state.conversionReportSpam
    },
  },
  mutations: {
    SET_CONVERSIONS(state, conversions) {
      state.conversions = conversions
    },
    SET_VERIFIED_CONVERSIONS(state, verifiedConversions) {
      state.verifiedConversions = verifiedConversions
    },
    SET_CONVERSION_REPORT_SPAM(state, conversionReport) {
      state.conversionReportSpam = conversionReport
    },
  },
  actions: {
    fetchAllConversions({ commit }, args) {
      return new Promise((resolve, reject) => {
        useJwt.getConversionDigest(args)
          .then(result => {
            commit('SET_CONVERSIONS', result.data)
            resolve(result)
          })
          .catch(error => {
            commit('SET_CONVERSIONS', error.response.data)
            reject(error.response.data)
          })
      })
    },
    fetchVerifiedConversions({ commit }, args) {
      return new Promise((resolve, reject) => {
        useJwt.getVerifiedConversionDigest(args)
          .then(result => {
            commit('SET_VERIFIED_CONVERSIONS', result.data)
            resolve(result)
          })
          .catch(error => {
            commit('SET_VERIFIED_CONVERSIONS', error.response.data)
            reject(error.response.data)
          })
      })
    },
    fetchReportConversionSpam({ commit }, args) {
      return new Promise((resolve, reject) => {
        useJwt.getReportConversionSpam(args)
          .then(result => {
            commit('SET_CONVERSION_REPORT_SPAM', result.data)
            resolve(result)
          })
          .catch(error => {
            commit('SET_CONVERSION_REPORT_SPAM', error.response.data)
            reject(error.response.data)
          })
      })
    },
  },
}
