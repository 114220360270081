export default [

  /* CONVERSION ROUTES */

  { // browse all
    path: '/conversions/all',
    name: 'browse-conversions',
    component: () => import('@/views/conversions/BrowseConversion.vue'),
    alias: '/conversions',
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // browse new
    path: '/conversions/new',
    name: 'browse-conversions-new',
    component: () => import('@/views/conversions/BrowseConversion.vue'),
    props: {
      pageTitle: 'New Conversions',
      filterParams: { status: 'new' },
    },
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // browse verified
    path: '/conversions/verified',
    name: 'browse-conversions-verified',
    component: () => import('@/views/conversions/ApprovedConversion.vue'),
    props: {
      pageTitle: 'Verified Conversions',
      filterParams: { status: 'verified' },
    },
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // browse won
    path: '/conversions/won',
    name: 'browse-conversions-won',
    component: () => import('@/views/conversions/BrowseConversion.vue'),
    props: {
      pageTitle: 'Won Conversions',
      filterParams: { status: 'won' },
    },
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // browse rejected
    path: '/conversions/rejected',
    name: 'browse-conversions-rejected',
    component: () => import('@/views/conversions/BrowseConversion.vue'),
    props: {
      pageTitle: 'Rejected Conversions',
      filterParams: { status: 'rejected' },
    },
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // browse spam
    path: '/conversions/spam',
    name: 'browse-conversions-spam',
    component: () => import('@/views/conversions/BrowseConversion.vue'),
    props: {
      pageTitle: 'Spam Conversions',
      filterParams: { status: 'spam' },
    },
    meta: {
      resource: 'Conversion',
      action: 'browse',
    },
  },
  { // read
    path: '/conversions/view/:id',
    name: 'view-conversion',
    component: () => import('@/views/conversions/ViewConversion.vue'),
    meta: {
      resource: 'Conversion',
      action: 'read',
    },
  },
  { // read
    path: '/conversions/view/:id/verify',
    name: 'verify-conversion',
    component: () => import('@/views/conversions/ViewConversion.vue'),
    meta: {
      resource: 'Conversion',
      action: 'read',
    },
  },
  /* { // update
    path: '/conversions/edit/:id',
    name: 'edit-conversion',
    component: () => import('@/views/conversions/EditConversion.vue'),
    meta: {
      resource: 'Conversion',
      action: 'update',
    },
  },
  { // create
    path: '/conversions/create',
    name: 'create-conversion',
    component: () => import('@/views/conversions/AddConversion.vue'),
    meta: {
      resource: 'Conversion',
      action: 'create',
    },
  }, */
]
