<template>
  <div
    class="btn-support show"
  >
    <!-- We have wrapper because ripple effect give position relative to this absolute positioned btn -->
    <!-- Hence due to that our btn get lost -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="info"
      class="btn-icon"
      @click="openFreshdesk"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>Help</span>
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { EventBus } from '@/libs/eventbus'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  data() {
    return {
      userData: {},
      supportData: {},
      currentAgency: {},
      currentCustomer: {},
    }
  },
  created() {
    EventBus.$on('open-supportdesk', () => {
      console.log('open-supportdesk')
      this.openFreshdesk()
    })

    this.prefillTicket()
  },
  methods: {
    openFreshdesk() {
      this.prefillTicket()
      this.$window.FreshworksWidget('open')
    },

    async prefillTicket() {
      /* prefill support ticket */
      this.userData = JSON.parse(localStorage.getItem('userData'))
      this.supportData = JSON.parse(localStorage.getItem('supportData'))
      if (this.supportData) {
        this.currentAgency = this.supportData.agency
        this.currentCustomer = this.supportData.customer
      }

      // retrieve agency details
      if (
        this.userData
        && (
          !this.supportData
          || (this.supportData && !this.supportData.agency)
        )
      ) {
        this.currentAgency = await this.getAgencyData()
        this.supportData = { ...this.supportData, agency: this.currentAgency }
        localStorage.setItem('supportData', JSON.stringify(this.supportData))
      }

      // retrieve google customer details
      if (
        this.userData && this.userData.google_access
        && (
          !this.supportData
          || (this.supportData && !this.supportData.customer)
        )
      ) {
        this.currentCustomer = await this.getCustomerData()
        this.supportData = { ...this.supportData, customer: this.currentCustomer }
        localStorage.setItem('supportData', JSON.stringify(this.supportData))
      }

      // prefill support ticket
      const googlePrefill = `  ${this.supportData.customer.customer_descriptive_name}\n  GCID: ${this.supportData.customer.customer_id} \n`
      const prefillDesc = `****** DO NOT DELETE ******\n  ${this.supportData.agency.name}\n  ID: ${this.supportData.agency.acuuid}\n${googlePrefill}****************************** \nPLEASE DESCRIBE YOUR ISSUE:\n\n`

      if (this.userData) {
        this.$window.FreshworksWidget('prefill', 'ticketForm', {
          name: this.userData.name,
          email: this.userData.email,
          description: prefillDesc,
        })
      }
    },

    async getAgencyData() {
      let currentAgency = {}

      // get agency clients
      await useJwt.getAgencyClients()
        .then(response => {
          const agencyData = response.data.data
          if (agencyData.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            currentAgency = agencyData[0] // TODO: SELECT OTHER AGENCY
            this.currentAgency = currentAgency
          }
        })
        .catch(error => {
          console.log('getAgencyClients ERROR', error.response)
        })

      return currentAgency
    },

    async getCustomerData() {
      let currentCustomer = {}

      // get data of logged in user
      await useJwt.getAdCustomers()
        .then(response => {
          const customerData = response.data.data
          const currentCustomerId = this.supportData.agency.google_customer_id

          if (currentCustomerId && customerData.length > 0) {
            const selectedCustomer = customerData.find(item => item.customer_id === currentCustomerId)
            currentCustomer = selectedCustomer
            this.currentCustomer = currentCustomer
          }
        })
        .catch(error => {
          console.log('getAdCustomers ERROR', error.response.data)
        })

      return currentCustomer
    },
  },

}
</script>

<style lang="scss" scoped>
.btn-support {
  position: fixed;
    bottom: 5%;
    right: 30px;
    z-index: 99;

    opacity: 1;
    transition: all .5s ease;

    &:hover {
      opacity: 1;
    }
}
</style>
