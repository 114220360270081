export default [

  /* CONVERSION ROUTES */

  { // browse all
    path: '/phone',
    name: 'browse-phone',
    component: () => import('@/views/phone/PhoneWizard.vue'),
    meta: {
      resource: 'Phone',
      action: 'create',
    },
  },
]
