export default [

  /* SETTINGS ROUTES */

  { // default settings -- TODO: redirect page
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/ManageProfile.vue'),
    meta: {
      resource: 'Account',
      action: 'view',
    },
  },
  { // view profile settings
    path: '/settings/profile',
    name: 'settings-profile',
    component: () => import('@/views/settings/ManageProfile.vue'),
    meta: {
      resource: 'Account',
      action: 'view',
    },
  },
  { // view current
    path: '/settings/billing',
    name: 'settings-billing',
    component: () => import('@/views/settings/ManageBilling.vue'),
    meta: {
      resource: 'Account',
      action: 'view',
    },
  },
  { // view current
    path: '/settings/users',
    name: 'settings-users', // 'manage-users'
    component: () => import('@/views/settings/ManageUsers.vue'),
    meta: {
      resource: 'Account',
      action: 'view',
    },
  },
]
